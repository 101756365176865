<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          v-on="on"
          v-bind="attrs"
          class="block-icon mr-2 button-height-unset"
          @click="recuperationPixel = true"
        >
          <font-awesome-icon icon="recycle" />
        </div>
      </template>
      <span>Récupérer les factures de pixel</span>
    </v-tooltip>
    <v-dialog
      v-model="recuperationPixel"
      :max-width="step == 0 ? '850px' : '1400px'"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Récupérer les factures de pixel</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('recuperationPixel')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <template v-if="step == 0">
            <v-form ref="formRecuperationFact" lazy-validation>
              <v-row class="mt-4 ">
                <v-col cols xs="12" sm="6" md="3">
                  <v-select
                    v-model="key"
                    :items="[
                      { key: 'DepotLotId', value: 'ID Lot' },
                      { key: 'FicheId', value: 'Numéro de dossier' }
                    ]"
                    item-text="value"
                    item-value="key"
                    dense
                    outlined
                    hide-details
                    color="#704ad1"
                    item-color="#704ad1"
                    label="Récupérer par"
                    no-data-text="Aucun élément trouvé"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select
                ></v-col>

                <v-col cols xs="12" sm="6" md="9">
                  <v-text-field
                    :label="
                      key == 'DepotLotId' ? 'ID de lot' : 'Numéro de dossier'
                    "
                    v-model="idLot"
                    outlined
                    required
                    dense
                    :persistent-placeholder="true"
                    :rules="
                      key == 'DepotLotId'
                        ? [v => !!v || 'ID lot est obligatoire']
                        : [v => !!v || 'Numéro de dossier est obligatoire']
                    "
                    class="msg-error "
                    validate-on-blur
                    color="#704ad1"
                  ></v-text-field
                ></v-col>
              </v-row> </v-form
          ></template>
          <template v-else>
            <v-card-text class="body-card">
              <div class="icon-download">
                <!-- <v-tooltip bottom color="#311B92">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="block-icon mr-2"
                      v-on="on"
                      v-bind="attrs"
                      @click.prevent.stop="downloadContentAutoFacture()"
                    >
                      <font-awesome-icon icon="cloud-download-alt" />
                    </div>
                  </template>
                  <span>Télécharger rapport</span>
                </v-tooltip> -->
              </div>

              <div id="divFactureAuto" ref="divFactureAuto">
                <!-- CREATED FACTURE  -->
                <div
                  class=" m-1 d-block"
                  v-if="message && message.created && message.created.length"
                >
                  <div class="titleFiche">Les factures crées :</div>
                  <div class=" body">
                    <v-simple-table
                      v-if="Array.isArray(message.created)"
                      class="table-master mt-1"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center text-dark">Vendeur</th>
                            <th class="text-center text-dark">
                              Nom client
                            </th>
                            <th class="text-center text-dark">Lot</th>
                            <th class="text-center text-dark">
                              Numéro Facture
                            </th>
                            <th class="text-center text-dark">
                              MT TTC facture
                            </th>
                            <th class="text-center text-dark">
                              Organisme
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in message.created"
                            :key="'genereted' + index"
                          >
                            <td class="text-center">{{ item.vendeur }}</td>
                            <td class="text-center">{{ item.client }}</td>
                            <td class="text-center">{{ item.lot }}</td>
                            <td class="text-center">{{ item.num }}</td>

                            <td class="text-center">
                              {{ item.montant_ttc }} €
                            </td>
                            <td class="text-center">{{ item.organisme }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
                <!-- UPDATED FACTURE  -->
                <div
                  class=" m-1 d-block"
                  v-if="message && message.updated && message.updated.length"
                >
                  <div class="titleFiche">Les factures modifiées :</div>
                  <div class=" body">
                    <v-simple-table
                      v-if="Array.isArray(message.updated)"
                      class="table-master mt-1"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center text-dark">Vendeur</th>
                            <th class="text-center text-dark">
                              Nom client
                            </th>
                            <th class="text-center text-dark">Lot</th>
                            <th class="text-center text-dark">
                              Numéro Facture
                            </th>
                            <th class="text-center text-dark">
                              MT TTC facture
                            </th>
                            <th class="text-center text-dark">
                              Organisme
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in message.updated"
                            :key="'genereted' + index"
                          >
                            <td class="text-center">{{ item.vendeur }}</td>
                            <td class="text-center">{{ item.client }}</td>
                            <td class="text-center">{{ item.lot }}</td>
                            <td class="text-center">{{ item.num }}</td>

                            <td class="text-center">
                              {{ item.montant_ttc }} €
                            </td>
                            <td class="text-center">{{ item.organisme }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
                <!-- DECISION FACTURE  -->

                <div
                  class=" m-1 d-block"
                  v-if="message && message.decision && message.decision.length"
                >
                  <div class="titleFiche">les factures à modifier :</div>
                  <div class=" body">
                    <v-simple-table
                      v-if="Array.isArray(message.decision)"
                      class="table-master mt-1"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center text-dark">
                              <v-checkbox
                                class="checked-expanded mt-0"
                                v-model="checkAllUpdateFact"
                                color="#704ad1"
                                :true-value="true"
                                :false-value="false"
                                hide-details
                                @change="changeAllCheckFact"
                              ></v-checkbox>
                            </th>
                            <!-- <th class="text-center text-dark">Vendeur</th>
                            <th class="text-center text-dark">
                              Nom client
                            </th>
                            <th class="text-center text-dark">Lot</th> -->
                            <th class="text-center text-dark">
                              Numéro Facture
                            </th>
                            <th class="text-center text-dark">
                              Numéro de dossier
                            </th>
                            <th class="text-center text-dark">
                              Société
                            </th>
                            <th class="text-center text-dark">
                              Client
                            </th>
                            <th class="text-center text-dark">
                              Client facture
                            </th>

                            <th class="text-center text-dark">
                              Total Net HT
                            </th>

                            <th class="text-center text-dark">
                              Total TVA
                            </th>
                            <th class="text-center text-dark">
                              MT TTC facture
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(item, index) in message.decision">
                            <tr :key="'genereted' + index">
                              <td
                                class="text-center"
                                :rowspan="computedCheckDiff(item) ? 2 : 1"
                              >
                                <v-checkbox
                                  class="checked-expanded mt-0"
                                  v-model="item.check"
                                  color="#704ad1"
                                  :true-value="true"
                                  :false-value="false"
                                  hide-details
                                  @change="checkDecision"
                                ></v-checkbox>
                              </td>
                              <!-- <td class="text-center">{{ item.vendeur }}</td>
                              <td class="text-center">{{ item.client }}</td>
                              <td class="text-center">{{ item.lot }}</td> -->
                              <td
                                class="text-center"
                                style="min-width:140px"
                                :rowspan="computedCheckDiff(item) ? 2 : 1"
                              >
                                {{ item.num }}
                              </td>
                              <td class="text-center">
                                {{ item.num_dossier }}
                              </td>
                              <td class="text-center">{{ item.vendeur }}</td>
                              <td class="text-center">{{ item.organisme }}</td>
                              <td class="text-center">{{ item.client }}</td>

                              <td class="text-center">
                                {{ item.total_net_ht }}
                              </td>

                              <td class="text-center">
                                <div class="flex-div">
                                  {{ item.total_tva }}
                                  <popoverTva
                                    :tvaList="item.produits"
                                  ></popoverTva>
                                </div>
                              </td>
                              <td class="text-center">
                                {{ item.montant_ttc }} €
                              </td>
                            </tr>
                            <!-- lige decision data  -->
                            <tr
                              :key="'ligne-decision' + index"
                              v-if="computedCheckDiff(item)"
                            >
                              <!-- Numéro dossier  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red':
                                    item.num_dossier !== item.num_dossier_new
                                }"
                              >
                                {{ item.num_dossier_new }}
                              </td>
                              <!-- Société  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red': item.vendeur !== item.vendeur_new
                                }"
                              >
                                {{ item.vendeur_new }}
                              </td>
                              <!-- Organisme  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red':
                                    item.organisme !== item.organisme_new
                                }"
                              >
                                {{ item.organisme_new }}
                              </td>

                              <!-- Client facture  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red': item.client !== item.client_new
                                }"
                              >
                                {{ item.client_new }}
                              </td>

                              <!-- Net HT  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red':
                                    item.total_net_ht !== item.total_net_ht_new
                                }"
                              >
                                {{ item.total_net_ht_new }}
                              </td>

                              <td
                                class="text-center"
                                :class="{
                                  'color-red':
                                    item.total_tva !== item.total_tva_new
                                }"
                              >
                                <div class="flex-div">
                                  {{ item.total_tva_new }}
                                  <popoverTva
                                    :tvaList="item.produits_new"
                                  ></popoverTva>
                                </div>
                              </td>
                              <!-- Montant ttc  -->
                              <td
                                class="text-center"
                                :class="{
                                  'color-red':
                                    item.montant_ttc !== item.montant_ttc_new
                                }"
                              >
                                {{ item.montant_ttc_new }} €
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
                <!-- NOT CREATED FACTURE  -->
                <div
                  class=" m-1 d-block"
                  v-if="
                    message && message.no_dossier && message.no_dossier.length
                  "
                >
                  <div class="titleFiche">
                    Les factures non crées (Le dossier n'existe pas ) :
                  </div>
                  <div class=" body">
                    <v-simple-table
                      v-if="Array.isArray(message.no_dossier)"
                      class="table-master mt-1"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center text-dark">Vendeur</th>
                            <th class="text-center text-dark">
                              Nom client
                            </th>
                            <th class="text-center text-dark">
                              Numéro Facture
                            </th>
                            <th class="text-center text-dark">
                              Numéro Dossier
                            </th>
                            <th class="text-center text-dark">
                              MT TTC
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in message.no_dossier"
                            :key="'genereted' + index"
                          >
                            <td class="text-center">{{ item.vendeur }}</td>
                            <td class="text-center">{{ item.client }}</td>
                            <td class="text-center">{{ item.num }}</td>
                            <td class="text-center">{{ item.num_dossier }}</td>
                            <td class="text-center">
                              {{ item.montant_ttc }} €
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
              </div>
            </v-card-text>
          </template>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul
            v-if="Array.isArray(error)"
            class="mb-0"
            style="list-style-type: none"
          >
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="
              step == 0 ? submitRecuperationPixelFact() : updateFactPixel()
            "
            v-if="step == 0 || (step == 1 && checkUpdated)"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="
              step == 0 ? hideModal('recuperationPixel') : reloadData()
            "
          >
            {{ step == 0 ? 'Annuler' : 'Fermer' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import html2pdf from 'html2pdf.js';

export default {
  data() {
    return {
      recuperationPixel: false,
      loading: false,
      error: null,
      idLot: null,
      message: null,
      step: 0,
      checkAllUpdateFact: false,
      key: 'DepotLotId'
    };
  },
  computed: {
    checkUpdated() {
      return this.message?.decision?.length;
    },
    computedCheckDiff() {
      return function(item) {
        return (
          item.montant_ttc !== item.montant_ttc_new ||
          item.organisme !== item.organisme_new ||
          item.num_dossier !== item.num_dossier_new ||
          item.total_net_ht !== item.total_net_ht_new ||
          item.client !== item.client_new ||
          item.vendeur !== item.vendeur_new ||
          item.total_tva !== item.total_tva_new
        );
      };
    }
  },
  methods: {
    ...mapActions(['recupereFacturePixel']),
    hideModal(ref) {
      this[ref] = false;
      this.$refs.formRecuperationFact?.reset();
      this.resetModal();
      this.$emit('reloadData');
    },
    resetModal() {
      this.idLot = null;
      this.error = null;
      this.message = null;
      this.step = 0;
      this.checkAllUpdateFact = false;
      this.key = 'DepotLotId';
    },
    async submitRecuperationPixelFact() {
      this.error = null;
      if (this.$refs.formRecuperationFact.validate()) {
        this.loading = true;
        const response = await this.recupereFacturePixel({
          value: this.idLot,
          key: this.key
        });
        if (response.succes) {
          this.message = response.data;
          if (this.message.decision) {
            this.message.decision = this.message.decision.map(i => {
              return { ...i, check: false };
            });
          }
          this.loading = false;
          this.step = 1;
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    reloadData() {
      this.hideModal('recuperationPixel');
      //   this.$emit('reloadData');
    },
    async updateFactPixel() {
      this.error = null;

      if (
        this.message &&
        this.message.decision &&
        this.message.decision.length > 0
      ) {
        if (this.message.decision.filter(i => i.check == true).length > 0) {
          this.loading = true;
          let ids = this.message.decision.filter(i => i.check == true);
          const response = await this.recupereFacturePixel({ ids: ids });
          if (response.succes) {
            this.message = response.data;
            this.loading = false;
          } else {
            this.error = response.error;
            this.loading = false;
          }
        } else {
          this.error = 'Sélectionnée au moins une facture à modifier';
        }
      } else {
        this.reloadData();
      }
    },
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divFactureAuto, {
        margin: 1,
        filename: 'rapport-facture-pixel.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'paysage' }
      });
    },
    changeAllCheckFact() {
      this.message.decision.map(i => (i.check = this.checkAllUpdateFact));
    },
    checkDecision() {
      this.checkAllUpdateFact =
        this.message.decision.filter(i => i.check == true).length ==
        this.message.decision.length
          ? true
          : false;
    }
  },
  components: {
    popoverTva: () => import('./popoverTva.vue')
  }
};
</script>

<style scoped lang="scss">
.titleFiche {
  color: #704ad1;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}
.table-master {
  table,
  td,
  tr,
  th {
    border: 1px solid #e0e0e0;
  }
}
</style>
