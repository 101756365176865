<template>
  <div
    class="interface-facture"
    :class="{ 'interface-facture-decalage': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('GFPXTHFDF')"
        >
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedDebut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_debut')"
                @blur="date_debut = parseDate(dateFormattedDebut)"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              no-title
              locale="fr"
              :allowed-dates="disabledStartDate"
              color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('GFPXTHFDF')"
        >
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedFin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleResetDate('date_fin')"
                @blur="date_fin = parseDate(dateFormattedFin)"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              :allowed-dates="disabledEndDate"
              no-title
              locale="fr"
              color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!--VUE DISPLAY-->
        <div class="select-input-vuetify" v-if="checkPermission('GFPXTHFVA')">
          <v-select
            v-model="groupedPixel"
            :items="vueOptions"
            dense
            outlined
            hide-details
            class="ma-2"
            color="#704ad1"
            item-color="#704ad1"
            label="Vue à afficher"
            @change="handleFilter"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <v-progress-circular
          class="ml-2"
          v-if="getLoaderPixelFacture"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorPixelFacture">
            <ul v-if="Array.isArray(getErrorPixelFacture)" class="mb-0">
              <li v-for="(e, index) in getErrorPixelFacture" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorPixelFacture }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('GFPXTHSE')"
            hiddenContent="true"
          />
        </div>
        <!-- RECUPERATION FACTURE PIXEL  -->
        <recuperation-pixel-fact
          @reloadData="handleFilter"
          v-if="checkPermission('GFPXTHFGFP')"
        ></recuperation-pixel-fact>
        <!-- EXPORT EXCEL -->
        <export-dynamic
          v-if="
            checkPermission('GFPXTHFEZIP') || checkPermission('GFPXTHFEXLS')
          "
          :filter="computedFilter"
          :selected="computedCheckRowsExport"
          :exportXls="exportFactureLibre"
          :exportZip="downloadZIP"
          :zipRequired="checkPermission('GFPXTHFEZIP')"
          :xlsRequired="checkPermission('GFPXTHFEXLS')"
          :famille="famille"
          hiddenContent="true"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="checkPermission('GFPXTHFL') || checkPermission('GFPXTHFD')"
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-sci"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- ORGANISME -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GFPXTHFD')"
                  v-model="isLocalStorageOrganisme"
                  :items="getListOrganismeObligee"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Délégateur"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :loading="getLoadingDataFilterObligeeThProjects"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageOrganisme.length - 0 }} Délégateur)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageOrganisme.length - 0 }} Délégateur)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- LOTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GFPXTHFL')"
                  v-model="isLocalStorageLots"
                  :items="getListLotsOligee"
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  label="Lots"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetX: true
                  }"
                  :loading="getLoadingDataFilterObligeeThProjects"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageLots.length - 0 }} Lots)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageLots.length - 0 }} Lots)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>

    <div class="content-cadre-table">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut | formateDate }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin | formateDate }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >
          <span v-if="groupedPixel !== null" class="item-filter-style"
            >Vue à afficher :
            {{ groupedPixel == 1 ? 'Par Délégateur' : 'Par Lots' }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- ORGANISME -->
        <v-col
          :cols="computedLengthFilter"
          v-if="organisme.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter ml-3">Délégateur : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.name + 'proprietaire'"
                v-for="v in organisme"
                close
                @click:close="
                  remove(
                    v,
                    'organisme',
                    'handleFilter',
                    'isLocalStorageOrganisme'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>

        <!-- LOTS -->
        <v-col
          :cols="computedLengthFilter"
          v-if="lots.length"
          class="space-table-filter"
        >
          <span class="title-block-chip-filter">Lots : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s + 'proprietaire'"
                v-for="s in lots"
                close
                @click:close="
                  remove(s, 'lots', 'handleFilter', 'isLocalStorageLots')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT CLIENT -->
      </v-row>
      <!-- TABLE FACTURE MENSUELLE -->
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="getPixelFacture"
        @item-expand="getPixelFacture"
        :items-per-page="perPage"
        :class="{
          'height-filter-chips-table': returnTableChips && !returnTableFiltre,
          'height-filter-table': returnTableFiltre && !returnTableChips,
          'height-filter-tous': returnTableChips && returnTableFiltre
        }"
        class="table-facture-pixel"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        item-key="name"
        hide-action
        :no-data-text="
          getLoaderPixelFacture
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <!-- HEADERS TABLE -->
        <template v-slot:[`header.check_all`]="{ header }">
          <td>
            <span v-if="header.value != 'check_all'"> {{ header.label }}</span>
            <div v-else>
              <v-checkbox
                class="checked-global"
                v-model="checkAll"
                @change="checkAllFunction"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </div>
          </td>
        </template>
        <!-- BODY TABLE -->
        <template v-slot:[`item.check_all`]="{ item }">
          <td class="custom-cell sticky-header-1">
            <v-checkbox
              class="checked-groupe"
              v-model="item.check"
              @change="checkAllVerif(item)"
              color="#704ad1"
              :true-value="true"
              :false-value="false"
              hide-details
            ></v-checkbox>
          </td>
        </template>
        <!--  ICON EXPANDED -->
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell sticky-header-2">
            <button
              class="btn btn-anomalie"
              v-if="item && item.factures && item.factures.length > 0"
              @click="showRowDetailsVueDefault(item)"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.grouped`]="{ item }">
          <td class="custom-cell">{{ item.name ? item.name : '-' }}</td>
        </template>
        <template v-slot:[`item.grouped_value`]="{}">
          <td class="custom-cell">-</td>
        </template>

        <template v-slot:[`item.Acheteur`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.Vendeur`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.num`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.total_net_ht`]="{ item }">
          <td class="custom-cell">{{ item.total_net_ht }} €</td>
        </template>
        <template v-slot:[`item.total_tva`]="{ item }">
          <td class="custom-cell">{{ item.total_tva }} €</td></template
        >
        <template v-slot:[`item.total_ttc`]="{ item }">
          <td class="custom-cell">{{ item.total_ttc }} €</td></template
        >
        <template v-slot:[`type`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reste_charge`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <!-- <template v-slot:[`base_tva1`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`base_tva2`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`base_tva3`]="{}">
          <td class="custom-cell">-</td>
        </template> -->
        <!-- <template v-slot:[`item.total_tva1`]="{}">
          <td class="custom-cell">-</td></template
        >
        <template v-slot:[`item.total_tva2`]="{}">
          <td class="custom-cell">-</td></template
        >
        <template v-slot:[`item.total_tva3`]="{}">
          <td class="custom-cell">-</td></template
        > -->

        <!-- EXPANDED TABLE -->
        <template v-slot:[`expanded-item`]="{ headers, item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="item.showDetails"
          >
            <table :items="item.factures" class="table-expanded">
              <tbody>
                <template v-for="(data, index) in item.factures">
                  <tr :key="data.id + 'pixel' + index">
                    <!-- CHECK -->
                    <td
                      class="td-expanded sticky-header-1"
                      style="width:40px !important; min-width : 40px !important"
                    >
                      <v-checkbox
                        class="checked-expanded"
                        v-model="data.check"
                        color="#704ad1"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <!-- VIDE -->
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px !important; min-width : 40px !important"
                    >
                      <!-- {{ data.FactureAvoir }} -->
                      <button
                        v-if="data.FactureAvoir.length > 0"
                        class="btn btn-anomalie"
                        @click="data.showDetails = !data.showDetails"
                      >
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-right"
                          color="#704ad1"
                          title="Afficher Facture(s) Avoir(s)"
                          v-if="data.showDetails == false"
                        />
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-down"
                          color="#704ad1"
                          title="Masquer Facture(s) Avoir(s)"
                          v-if="data.showDetails == true"
                        />
                      </button>
                    </td>
                    <!-- ORGANISME /LOT  -->

                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACDL')"
                    >
                      {{ item.name }}
                    </td>
                    <!-- GROUPED VALUE  -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACDL')"
                    >
                      {{ groupedPixel === 1 ? data.objet : data.acheteur }}
                    </td>
                    <!-- SOCIETE -->
                    <td class="td-expanded" v-if="checkPermission('GFPXTHACV')">
                      {{
                        data.vendeur && data.vendeur.nom_compagnie
                          ? data.vendeur.nom_compagnie
                          : data.vendeur
                      }}
                    </td>
                    <!-- CLIENT -->
                    <td class="td-expanded" v-if="checkPermission('GFPXTHACA')">
                      {{ data.acheteur }}
                    </td>
                    <!-- CLIENT FACTURE -->
                    <td class="td-expanded" v-if="checkPermission('GFPXTHACA')">
                      {{ data.nom_prenom_client }}
                    </td>
                    <!-- FACTURE  -->
                    <td class="td-expanded" v-if="checkPermission('GFPXTHACN')">
                      <div class="flex-div">
                        {{ data.num }}
                        <VisualisationFacture
                          :awesome="true"
                          :facture="data.id"
                          :permission="checkPermission('GFPXTHFVFP')"
                        ></VisualisationFacture>
                        <div
                          v-if="checkPermission('GFPXTHFTFP')"
                          class="block-icon-table-facture ml-1 mr-1"
                          @click.prevent="downloadFacture(data.id)"
                          title="Télécharger facture"
                        >
                          <font-awesome-icon icon="arrow-alt-circle-down" />
                        </div>
                      </div>
                    </td>
                    <!-- TYPE -->
                    <td class="td-expanded" v-if="checkPermission('GFPXTHACT')">
                      {{ data.type }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACDD')"
                    >
                      {{ data.date_creation | formateDate }}
                    </td>

                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACMHT')"
                    >
                      {{ data.total_ht }} €
                    </td>
                    <!-- BASE TVA1  -->
                    <!-- <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTVA1')"
                  >
                    {{ data.baseTVA1 }}
                  </td>
                  <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTTVA1')"
                  >
                    {{ data.totalTVA1 }} €
                  </td> -->
                    <!-- BASE TVA2  -->
                    <!-- <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTVA2')"
                  >
                    {{ data.baseTVA2 }}
                  </td>
                  <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTTVA2')"
                  >
                    {{ data.totalTVA2 }} €
                  </td> -->
                    <!-- BASE TVA3  -->
                    <!-- <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTVA3')"
                  >
                    {{ data.baseTVA3 }}
                  </td>
                  <td
                    class="td-expanded"
                    v-if="checkPermission('GFPXTHACTTVA3')"
                  >
                    {{ data.totalTVA3 }} €
                  </td> -->
                    <!-- TOTAL TVA  -->
                    <td
                      class="td-expanded "
                      v-if="checkPermission('GFPXTHACTTVAG')"
                    >
                      <div class="flex-div">
                        {{ data.total_tva }} €
                        <popoverTva :tvaList="data.produits"></popoverTva>
                      </div>
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACTTCC')"
                    >
                      {{ data.total_ttc }} €
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GFPXTHACRAC')"
                    >
                      {{ data.reste_charge }} €
                    </td>
                  </tr>
                  <template v-if="data.showDetails == true">
                    <tr v-for="avoir in data.FactureAvoir" :key="avoir.id">
                      <td
                        class="td-expanded sticky-header-1"
                        style="width:40px !important; min-width : 40px !important"
                      >
                        <v-checkbox
                          class="checked-expanded"
                          v-model="avoir.checkAvoir"
                          color="#704ad1"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <!-- VIDE -->
                      <td
                        class="td-expanded sticky-header-2"
                        style="width:40px !important; min-width : 40px !important"
                      >
                        <span> </span>
                      </td>
                      <!-- ORGANISME /LOT  -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACDL')"
                      >
                        {{ groupedPixel === 1 ? data.acheteur : data.objet }}
                      </td>
                      <!-- ORGANISME /LOT  -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACDL')"
                      >
                        {{ groupedPixel === 1 ? data.objet : data.acheteur }}
                      </td>
                      <!-- SOCIETE -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACV')"
                      >
                        {{
                          avoir.vendeur && avoir.vendeur.nom_compagnie
                            ? avoir.vendeur.nom_compagnie
                            : avoir.vendeur
                        }}
                      </td>
                      <!-- CLIENT -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACA')"
                      >
                        <div class="flex-div">
                          {{ avoir.acheteur }}
                        </div>
                      </td>

                      <!-- CLIENT FACTURE -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACA')"
                      >
                        {{ avoir.nom_prenom_client }}
                      </td>
                      <!-- FACTURE -->
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACN')"
                      >
                        <div class="flex-div">
                          {{ avoir.num }}
                          <VisualisationFacture
                            :awesome="true"
                            :facture="avoir.id"
                            :permission="checkPermission('GFPXTHFVFP')"
                          ></VisualisationFacture>
                          <div
                            v-if="checkPermission('GFPXTHFTFP')"
                            class="block-icon-table-facture ml-1 mr-1"
                            @click.prevent="downloadFacture(avoir.id)"
                            title="Télécharger facture"
                          >
                            <font-awesome-icon icon="arrow-alt-circle-down" />
                          </div>
                        </div>
                      </td>
                      <!-- TYPE -->

                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACT')"
                      >
                        {{ avoir.type }}
                      </td>
                      <td
                        v-if="checkPermission('GFPXTHACDD')"
                        class="td-expanded"
                      >
                        {{ avoir.date_creation | formateDate }}
                      </td>

                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACMHT')"
                      >
                        {{ avoir.total_ht }} €
                      </td>
                      <!-- TOTAL TVA  -->
                      <td
                        class="td-expanded "
                        v-if="checkPermission('GFPXTHACTTVAG')"
                      >
                        <div class="flex-div">
                          {{ avoir.total_tva }} €
                          <popoverTva :tvaList="avoir.produits"></popoverTva>
                        </div>
                      </td>
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACTTCC')"
                      >
                        {{ avoir.total_ttc }} €
                      </td>
                      <td
                        class="td-expanded"
                        v-if="checkPermission('GFPXTHACRAC')"
                      >
                        {{
                          avoir.reste_charge ? avoir.reste_charge + ' €' : '-'
                        }}
                      </td>
                    </tr>
                  </template></template
                >
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ExportDynamic from '@/views/ThProject/frais/fraisDynamic/component/exportDynamic.vue';
import RecuperationPixelFact from './component/RecuperationPixelFact.vue';
export default {
  name: 'Gestion-Facture-Mensuel',
  data() {
    return {
      dateFormattedDebut: null,
      dateFormattedFin: null,
      expanded: [],
      singleExpand: false,
      selectedTable: [],
      menu: false,
      factureToShow: null,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      date_debut: null,
      date_fin: null,
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      famille: 'pixel',
      searchValue: null,
      checkAll: false,
      column: 'id',
      order: 'DESC',
      organisme: [],
      lots: [],
      groupedPixel: 1,
      vueOptions: [
        { text: 'Par Délégateur', value: 1 },
        { text: 'Par Lots', value: 0 }
      ],
      isLocalStorageOrganisme: [],
      isLocalStorageLots: []
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  computed: {
    ...mapGetters([
      'getShowNotifExportFile',
      'getLoaderPixelFacture',
      'getPixelFacture',
      'checkPermission',
      'getErrorPixelFacture',
      'TotalRowsPixel',
      'computedRouterLinkFacture',
      'getListOrganismeObligee',
      'getLoadingDataFilterObligeeThProjects',
      'getListLotsOligee',
      'getLoadingDataFilterObligeeThProjects',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.isLocalStorageOrganisme,
        this.isLocalStorageLots
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null ||
        this.groupedPixel !== null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (this.organisme.length || this.lots.length) {
        return true;
      }
      return false;
    },
    // PAGINATION TABLE
    totalPages() {
      if (this.TotalRowsPixel) {
        return Math.ceil(this.TotalRowsPixel / this.perPage);
      }
      return 1;
    },
    computedCheckChipFilter() {
      if (this.organisme.length > 0 || this.lots.length > 0) {
        return true;
      }
      return false;
    },
    computedCheckRowsExport() {
      let selected = [];
      this.getPixelFacture.forEach(element => {
        element.factures.forEach(facture => {
          if (facture.check == true) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedCheckedRows() {
      let tableF = this.getPixelFacture?.filter(f => {
        return f.factures.some(e => e.check == true);
      });
      return tableF;
    },
    computedFilter() {
      return {
        search: this.searchValue,
        lots: this.lots,
        organisme: this.organisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        col: this.column,
        famille: this.support,
        groupedPixel: this.groupedPixel
      };
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'grouped',
          text: this.groupedPixel ? 'Délégateur' : 'Lot',
          show: this.checkPermission('GFPXTHACDL'),
          sortable: false,
          class: 'societe-th'
        },
        {
          value: 'grouped_value',
          text: this.groupedPixel ? 'Lot' : 'Délégateur',
          show: this.checkPermission('GFPXTHACDL'),
          sortable: false,
          class: 'societe-th'
        },
        {
          value: 'Vendeur',
          text: 'Société',
          show: this.checkPermission('GFPXTHACV'),
          sortable: false,
          class: 'societe-th'
        },
        {
          value: 'Acheteur',
          text: 'Client',
          show: this.checkPermission('GFPXTHACA'),
          sortable: false
        },
        {
          value: 'nom_prenom_client',
          text: 'Client Facture',
          show: this.checkPermission('GFPXTHACA'),
          sortable: false
        },
        {
          value: 'num',
          text: 'N° Facture',
          show: this.checkPermission('GFPXTHACN'),
          sortable: false
        },
        {
          value: 'type',
          text: 'Type de  Facture',
          show: this.checkPermission('GFPXTHACT'),
          sortable: false
        },
        {
          value: 'date',
          text: 'Date',
          show: this.checkPermission('GFPXTHACDD'),
          sortable: false
        },

        {
          value: 'total_net_ht',
          text: 'Montant HT',
          show: this.checkPermission('GFPXTHACMHT'),
          sortable: false
        },
        // {
        //   value: 'base_tva1',
        //   text: 'Taux TVA 1 ',
        //   show: this.checkPermission('GFPXTHACTVA1'),
        //   sortable: false
        // },
        // {
        //   value: 'total_tva1',
        //   text: 'Total TVA 1 ',
        //   show: this.checkPermission('GFPXTHACTTVA1'),
        //   sortable: false
        // },
        // {
        //   value: 'base_tva2',
        //   text: 'Taux TVA 2',
        //   show: this.checkPermission('GFPXTHACTVA2'),
        //   sortable: false
        // },
        // {
        //   value: 'total_tva2',
        //   text: 'Total TVA 2 ',
        //   show: this.checkPermission('GFPXTHACTTVA2'),
        //   sortable: false
        // },
        // {
        //   value: 'base_tva3',
        //   text: 'Taux TVA 3',
        //   show: this.checkPermission('GFPXTHACTVA3'),
        //   sortable: false
        // },
        // {
        //   value: 'total_tva3',
        //   text: 'Total TVA 3',
        //   show: this.checkPermission('GFPXTHACTTVA3'),
        //   sortable: false
        // },
        {
          value: 'total_tva',
          text: 'Total TVA',
          show: this.checkPermission('GFPXTHACTTVAG'),
          sortable: false
        },
        {
          value: 'total_ttc',
          text: 'Montant TTC',
          show: this.checkPermission('GFPXTHACTTCC'),
          sortable: false
        },
        {
          value: 'reste_charge',
          text: 'Reste à charge',
          show: this.checkPermission('GFPXTHACRAC'),
          sortable: false
        }
      ];

      return fields.filter(item => item.show === true);
    }
  },
  watch: {
    date_debut(val) {
      this.dateFormattedDebut = this.formatDate(val);
    },
    date_fin(val) {
      this.dateFormattedFin = this.formatDate(val);
    }
  },
  methods: {
    ...mapActions([
      'getAllPixelFacture',
      'exportFactureLibre',
      'downloadZIP',
      'getAllDataForFilterObligee',
      'downloadPDF'
    ]),
    downloadFacture(id) {
      this.downloadPDF({ id: id });
    },
    showRowDetailsVueDefault(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      } else {
        const index = this.expanded.findIndex(
          expandedItem => expandedItem === item
        );
        if (index !== -1) {
          this.expanded.splice(index, 1);
        }
      }
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      this.organisme = [];
      this.lots = [];
      //INIT POPOVER DATA
      this.isLocalStorageLots = [];
      this.isLocalStorageOrganisme = [];
      this.handleFilter(true);
    },
    validerPopover() {
      //
      //SET FILTER POPOVER
      //
      this.lots = this.isLocalStorageLots;
      this.organisme = this.isLocalStorageOrganisme;
      //
      //END SET FILTER POPOVER
      //
      this.handleFilter(true);
      this.menu = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('-');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    handleResetDate(name) {
      this[name] = null;
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleFilter();
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    checkAllFunction() {
      this.getPixelFacture.map(item => {
        item.check = this.checkAll;
        item.factures.map(facture => {
          facture.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(facture => {
        facture.check = item.check;
      });
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.checkAll = false;
      this.setLocalStorageFactureMensuel();
      this.$nextTick(async () => {
        this.getAllPixelFacture({
          page: this.page,
          per_page: this.perPage,
          famille: this.famille,
          order: 'DESC',
          column: 'id',
          organisme: this.organisme,
          lots: this.lots,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          search: this.searchValue,
          groupedPixel: this.groupedPixel
        });
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageFactureMensuel();
    },
    setLocalStorageFactureMensuel() {
      localStorage.setItem(
        'frais-th-facture-pixel',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          organisme: this.organisme,
          lots: this.lots,
          searchValue: this.searchValue
        })
      );
    }
  },
  components: {
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    ExportDynamic,
    RecuperationPixelFact,
    popoverTva: () => import('./component/popoverTva.vue')
  },
  async mounted() {
    this.getAllDataForFilterObligee({});
    if (localStorage.getItem('frais-th-facture-pixel')) {
      var localStor = JSON.parse(
        localStorage.getItem('frais-th-facture-pixel')
      );
      this.date_debut = localStor.date_debut ? localStor.date_debut : null;
      this.date_fin = localStor.date_fin ? localStor.date_fin : null;
      this.searchValue = localStor.searchValue ? localStor.searchValue : null;
      this.lots = localStor.lots ? localStor.lots : [];
      this.isLocalStorageLots = this.lots;
      this.organisme = localStor.organisme ? localStor.organisme : [];
      this.isLocalStorageOrganisme = this.organisme;
    } else {
      this.setLocalStorageFactureMensuel();
    }
    this.handleFilter();
  }
};
</script>
